import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import { CHECK_IS_OCEANTICKET_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import CheckPointItem from "./_components/CheckPointItem";
import useOnChangeHandler from "./_hooks/useOnChangeHandler";

export default function LCLCheckPoint({
  isOceanticket,
}: {
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
}) {
  const { watch } = useFormContext<BidCreateFormData>();

  const bidType = watch("bidType");
  const cfsReceiving = watch("cfsReceiving");
  const incoterms = watch("incoterms");

  const { handleEffectCfsReceivingChangeForOcean } = useOnChangeHandler();

  return (
    <Grid item container direction={"column"} xs={11}>
      {bidType === "import" && (
        <>
          <CheckPointItem checkPoint="containLss" />

          <CheckPointItem checkPoint="containOceanSurcharge" />

          <CheckPointItem checkPoint="containDomesticFee" />

          {isOceanticket && (
            <CheckPointItem checkPoint="containsWarehouseFee" />
          )}
        </>
      )}

      {bidType === "export" && (
        <>
          <CheckPointItem
            checkPoint="cfsReceiving"
            disabled={
              (incoterms === "DDP" || incoterms === "DAP") &&
              cfsReceiving === "shipda"
            }
            handleEffectOnChange={handleEffectCfsReceivingChangeForOcean}
          />

          <CheckPointItem
            checkPoint="inlandTransportType"
            disabled={cfsReceiving === "direct"}
          />
        </>
      )}
    </Grid>
  );
}
