import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import CheckPointItem from "./_components/CheckPointItem";
import useOnChangeHandler from "./_hooks/useOnChangeHandler";

export default function FCLCheckPoint() {
  const { watch, setValue } = useFormContext<BidCreateFormData>();

  const bidType = watch("bidType");
  const endType = watch("endType");
  const startType = watch("startType");
  const containerStuffing = watch("containerStuffing");
  const cfsReceiving = watch("cfsReceiving");
  const incoterms = watch("incoterms");

  const {
    handleEffectContainerStuffingChange,
    handleEffectCfsReceivingChangeForOcean,
  } = useOnChangeHandler();

  return (
    <Grid item container direction={"column"} xs={11}>
      {bidType === "import" && (
        <>
          <CheckPointItem checkPoint="containLss" />

          <CheckPointItem checkPoint="containOceanSurcharge" />

          <CheckPointItem checkPoint="containDomesticFee" />

          {endType === "inland" && (
            <CheckPointItem checkPoint="containerAccessable" />
          )}
        </>
      )}

      {bidType === "export" && (
        <>
          {startType === "inland" && (
            <CheckPointItem
              checkPoint="containerStuffing"
              handleEffectOnChange={handleEffectContainerStuffingChange}
            />
          )}
          <CheckPointItem
            checkPoint="cfsReceiving"
            disabled={containerStuffing === "FALSE"}
            handleEffectOnChange={handleEffectCfsReceivingChangeForOcean}
          />
          <CheckPointItem
            checkPoint="inlandTransportType"
            disabled={cfsReceiving === "direct" && containerStuffing === "TRUE"}
          />
          {/* TODO: 인코텀즈보다 더 직관적인 로직으로 분기하면 더 좋을 듯 */}
          {(incoterms === "DDP" || incoterms === "DAP") && (
            <CheckPointItem checkPoint="containerDevanning" />
          )}
        </>
      )}
    </Grid>
  );
}
