import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import CheckPointItem from "./_components/CheckPointItem";
import useOnChangeHandler from "./_hooks/useOnChangeHandler";

export default function AIRCheckPoint() {
  const { watch } = useFormContext<BidCreateFormData>();

  const bidType = watch("bidType");
  const cfsReceiving = watch("cfsReceiving");

  const { handleEffectCfsReceivingChangeForAir } = useOnChangeHandler();

  return (
    <Grid item container direction={"column"} xs={11}>
      {bidType === "import" && (
        <>
          <CheckPointItem checkPoint="containDomesticFee" />
        </>
      )}

      {bidType === "export" && (
        <>
          <CheckPointItem
            checkPoint="cfsReceiving"
            handleEffectOnChange={handleEffectCfsReceivingChangeForAir}
          />

          <CheckPointItem
            checkPoint="inlandTransportType"
            disabled={cfsReceiving === "direct"}
          />
        </>
      )}
    </Grid>
  );
}
