import { ChangeEvent, useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

export default function useOnChangeHandler() {
  const { watch, setValue } = useFormContext<BidCreateFormData>();

  const incoterms = watch("incoterms");
  const cfsReceiving = watch("cfsReceiving");
  const containerStuffing = watch("containerStuffing");

  /**
   * 출발지 컨테이너 진입가능 여부 라디오버튼 변경 핸들러
   */
  const handleEffectContainerStuffingChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "TRUE") {
        setValue("cfsReceiving", "direct");
        setValue("inlandTransportType", "none");
        return;
      }

      setValue("cfsReceiving", "shipda");
      setValue("inlandTransportType", "sole");
    },
    [setValue]
  );

  /**
   * CFS 입고 라디오버튼 변경 핸들러 - FCL/LCL 전용
   */
  const handleEffectCfsReceivingChangeForOcean = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "direct") {
        setValue("inlandTransportType", "none");
        return;
      }

      setValue("inlandTransportType", "sole");
    },

    [setValue]
  );

  /**
   * CFS 입고 라디오버튼 변경 핸들러 - AIR 전용
   */
  const handleEffectCfsReceivingChangeForAir = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const allowsIncoterms = ["CIP", "CPT"];
      if (
        !incoterms ||
        !allowsIncoterms.includes(incoterms) ||
        containerStuffing
      ) {
        return;
      }

      const inlandTransportValue = cfsReceiving === "shipda" ? "none" : "sole";
      setValue("inlandTransportType", inlandTransportValue);
    },

    [cfsReceiving, containerStuffing, incoterms, setValue]
  );

  return {
    handleEffectContainerStuffingChange,
    handleEffectCfsReceivingChangeForOcean,
    handleEffectCfsReceivingChangeForAir,
  };
}
